/*global chrome*/
import React, { useEffect } from 'react';
import logo from "../../assets/icons/logo.png";
import magicLinkIcon from "../../assets/icons/magicLinkIcon.svg";
import axios from 'axios';
import { toastify } from '../../components/Toast';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Intercom from '../intercom/Intercom';
import { Helmet } from "react-helmet";
function SignUp(){
    const navigate = useNavigate();
    const [Email, setEmail] = React.useState("");
    const [Password, setPassword] = React.useState("");
    const [FirstName, setfirstName] = React.useState("");
    const [LastName, setlastName] = React.useState("");
    const [agree, setAgree] = React.useState(false);
    const user = {
        firstName: null,
        email: null,
        createdAt: Math.floor(Date.now() / 1000),
      };
    
    const sendTokenToChromeExtension = ({ extensionId, jwt }) => {
        try {
            if(chrome && chrome?.runtime){
                chrome.runtime.sendMessage(extensionId, { jwt }, response => {
                    // if (!response.success) {
                    //   console.log('error sending message', response);
                    //   return response;
                    // }
                    // console.log('Sucesss ::: ', response.message)
                
                });
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        // console.log(user,"user on login")
          },[user])
    useEffect(() => {
        // Check the user's login status (you might want to use your own logic)
        // const isUserLoggedIn = /* your logic to check if the user is logged in */;
        const isUserLoggedIn = localStorage.getItem('userData') ? true : false;
        // Redirect if the user is already logged in
        if (isUserLoggedIn) {
          navigate('/app/dashboard'); // Redirect to your main app page
        }
        const urlSearchParams = new URLSearchParams(window.location.search);
        const token = urlSearchParams.get('tokenId');
        const fetchInviteVerification = async() => {
            if(token){
                try {
                const response = await axios.post(`${process.env.REACT_APP_API}team/team-member-invite-verification`,
                {
                    token:token
                }
                );
                console.log(response);
                localStorage.setItem("settingsToken",token)
                // navigate('/signup')
                } catch (error) {
                console.log(error.response.data);
                localStorage.removeItem('settingsToken')
                navigate('/invitationExpired')
                // toastify("error", error?.response?.data?.message);
                }
            }
        }
        fetchInviteVerification();
        // Initialize Pixel after the script loads
        if (window.fbq) {
            window.fbq("init", "787696013297620", {
            autoConfig: true,
            domain: "zimzee.com,app.zimzee.com",
            });
            window.fbq("track", "PageView");
        }
      }, []);
    
    function changeAgree(event){
        setAgree(!agree);
    }

    function changeTxt(event){
        setEmail(event.target.value);                
    }

    function changePassword(event){
        setPassword(event.target.value);
    }

    function firstName(event){
        setfirstName(event.target.value);
    }

    function lastName(event){
        setlastName(event.target.value);
    }
    const handleSubmit = (e) => {
        // /setLoading(true);
		e.preventDefault();
        console.log(e.target.agree.value,"agree");
        if(!agree){
            toastify("error", "Please agree terms and conditions" );
            return;
        }
		const data = {
            firstName: e.target.firstName.value,
            lastName: e.target.lastName.value,
            email: e.target.email.value,
			password: e.target.password.value
		}
        axios
        .post(`${process.env.REACT_APP_API}users/register`, data)
        .then((response) => {
            console.log(response.data.message);
            localStorage.setItem('token', response.data.data.token);
            localStorage.setItem('userData', JSON.stringify(response.data.data.user));
            console.log(user,"user on login")
            // Intercom(user)
            if (window.Intercom) {
                window.Intercom('boot', {
                  api_base: 'https://api-iam.intercom.io',
                  app_id: 'm7xe1x6e',
                  name: response.data.data.user.firstName,
                  email: response.data.data.user.email,
                  created_at: response.data.data.user.createdAt,
                });
              }
            sendTokenToChromeExtension({ extensionId: 'mnocfefponilgkmehcdbmdojkeodoffd', jwt: response.data.data.token });
            toastify("success", response.data.message );
            setEmail("");
            setPassword("");
            setfirstName("");
            setlastName("");
            setTimeout(() => {
                navigate(`/app/userPricing`);
            }, 1000);
        }).catch( (err) => {
            console.log(err.response.data.message);
            toastify("error", err.response.data.message );
        });
      };

    return(
        <>
            <Helmet>
                {/* Add the Meta Pixel Script */}
                <script>
                  {`!function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');`}
                </script>
                <noscript>
                  {`<img height="1" width="1" style="display:none"
                  src="https://www.facebook.com/tr?id=787696013297620&ev=PageView&noscript=1" />`}
                </noscript>
            </Helmet>
            <div className="loginHolder min-h-[100vh] flex items-center bg-[#F3F4F6]">
            <div className="formHolder md:w-1/2 lg:w-2/5 mx-auto my-5 p-8 rounded-lg shadow">
                <div className="logo">
                    <img src={logo} />
                </div>
                <div className="mb-6">
                    <h3 className="text-xl mt-7 font-bold text-[24px]">Create New Account</h3>
                </div>
                <form onSubmit={handleSubmit} className="loginForm flex flex-col">
                {/* style={{borderColor:"#D9D9D9 !important", borderWidth: '1px'}} */}
                    <input className="border-2 border-[#D9D9D9] bg-white my-3 p-3 rounded-md" type="text" id="firstName" onChange={firstName} value={FirstName} placeholder="First Name" />
                    <input className="border-2 border-[#D9D9D9] bg-white my-3 p-3 rounded-md" type="text" id="lastName" onChange={lastName} value={LastName} placeholder="Last Name" />
                    <input className="border-2 border-[#D9D9D9] bg-white my-3 p-3 rounded-md" type="email" id="email" onChange={changeTxt} value={Email} placeholder="Your Email" />
                    <input className="bg-white border-2 border-[#D9D9D9] my-3 p-3 rounded-md" type="password" id="password" onChange={changePassword} value={Password} placeholder="Password" />
                    <button type="submit" className="button bg-[#FF6600] text-white py-2 rounded-[5px] mt-4">Sign Up</button>
                    <div className="mt-3 flex items-start">
                        <input className="block mt-2" type="checkbox"  name="agree" onChange={changeAgree}/>
                        <label className="ml-2 block">I agree to the terms and conditions as set out by the user agreement.</label>
                    </div>
                </form>
                <h2 className="mt-8 text-center">Already have an account? <a href="/" className="text-[#EE7900]">Sign in</a></h2>
            </div>
            <ToastContainer />
        </div>
        </>
    )
}

export default SignUp;